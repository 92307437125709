<template>
  <b-card>
    <h2>Ajouter un moyen de transport</h2>
    <b-form @submit.prevent="createTransportMeans">
      <b-row class="mt-2">
        <b-col md="6">
          <b-form-group
            label="Catégorie de camion *"
            label-for="mc-first-name"
          >
            <v-select
              id="pays"
              v-model="transportMeans.cat_camion_id"
              label="name"
              :options="categoryCamions"
              :reduce="categoryCamions.slug"
              placeholder="Catégorie de camion"
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Type de camion *"
            label-for="type_camion"
          >
            <validation-provider
              #default="{ errors }"
              name="type camion"
              rules="required"
            >
              <v-select
                id="type_camion"
                v-model="transportMeans.type_camion_id"
                label="libelle"
                size="sm"
                :options="typeCamions"
                :reduce="(typeCamions) => typeCamions.id"
                :state="errors.length > 0 ? false : null"
                placeholder="Type de camion"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>

          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="N° Chassis"
            label-for="num_chassis"
          >
            <validation-provider
              #default="{ errors }"
              name="numéro chassis"
              rules="required"
            >
              <b-form-input
                id="num_chassis"
                v-model="transportMeans.num_chassis"
                size="sm"
                :state="errors.length > 0 ? false : null"
                placeholder="Numéro chassis"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Immatriculation *"
            label-for="immatriculation"
          >

            <validation-provider
              #default="{ errors }"
              name="numéro d'immatriculation"
              rules="required"
            >
              <b-form-input
                id="immatriculation"
                v-model="transportMeans.num_immatriculation"
                size="sm"
                :state="errors.length > 0 ? false : null"
                placeholder="Numéro d'immatriculation"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Nombre d'essieux *"
            label-for="essieux"
          >
            <validation-provider
              #default="{ errors }"
              name="Nombre d'essieux"
              rules="required"
            >
              <b-form-input
                id="essieux"
                v-model.number="transportMeans.nombre_essieux"
                size="sm"
                :state="errors.length > 0 ? false : null"
                placeholder="Nombre d'essieux"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>

        <b-col md="6">
          <b-form-group
            label="Libellé *"
            label-for="marque"
          >
            <validation-provider
              #default="{ errors }"
              name="Libellé"
              rules="required"
            >
              <b-form-input
                id="marque"
                v-model="transportMeans.libelle"
                size="sm"
                :state="errors.length > 0 ? false : null"
                placeholder="Libellé"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="Disponibilité *"
            label-for="disponible"
          />
          <validation-provider
            name="disponible"
            rules="required"
          >
            <b-form-checkbox v-model="transportMeans.disponible">
              {{ transportMeans.disponible ? 'Oui' : 'Non' }}
            </b-form-checkbox>
          </validation-provider>
        </b-col>

        <!-- submit and reset -->
        <b-col md="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="submit"
            variant="primary"
            class=" float-right"
            :disabled="offreProcess"
          >
            Enregistrer
            <b-spinner
              v-if="offreProcess"
              small
              label="Loading..."
              variant="light"
              class="ml-1"
            />
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            variant="secondary"
            class="mr-1 float-right"
          >
            Rafraichir
          </b-button>
        </b-col>
      </b-row>
    </b-form>

  </b-card>

</template>

<script>
import vSelect from 'vue-select'
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash'
import {
  BCard, BRow, BCol, BSpinner, BFormGroup, BFormInput, BForm, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { ValidationProvider } from 'vee-validate'
import {
  required,
} from '@validations'

import {
  onMounted, reactive, ref, watch,
} from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import useSocieteTransporteurs from '@/services/societe/societeTransporteurService'
// eslint-disable-next-line import/no-cycle
import useCategoryCamions from '@/services/admin/categoryCamionService'
// eslint-disable-next-line import/no-cycle
import useTypeCamions from '@/services/admin/typeCamionService'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BSpinner,
    vSelect,
    BCard,
    ValidationProvider,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },

  setup() {
    const {
      getCategoryCamions, categoryCamions,
    } = useCategoryCamions()
    const {
      handleCreateTransporteurMeans, offreProcess,
    } = useSocieteTransporteurs()
    const {
      typeCamions, getTypesCamionByCategoryCamionBySlug, typeCamion,
    } = useTypeCamions()

    onMounted(async () => {
      await getCategoryCamions()

      // Set the initial number of items
    })
    const transportMeans = reactive({
      cat_camion_id: '',
      type_camion_id: '',
      libelle: '',
      slug: '',
      description: '',
      num_chassis: '',
      num_immatriculation: '',
      disponible: '',
      nombre_essieux: '',

    })

    const createTransportMeans = async () => {
      handleCreateTransporteurMeans(transportMeans)
      console.clear()
    }

    const currentType = async data => {
      typeCamion.value = ''
      await getTypesCamionByCategoryCamionBySlug(data.slug)
      console.clear()
    }

    const telephonefull = ref('')
    watch(() => (transportMeans.telephonefull), () => {
      transportMeans.telephone = ''
      transportMeans.telephone = telephonefull.value.formattedNumber
      console.clear()
    })

    watch(() => _.cloneDeep(transportMeans.cat_camion_id), value => {
      currentType(value)
    })

    return {
      transportMeans,
      createTransportMeans,
      required,
      categoryCamions,
      offreProcess,
      typeCamions,
    }
  },

  data() {
    return {
      selected: { title: 'Ville' },
      option: [{ title: 'Ville' }, { title: 'Cotonou' }, { title: 'Porto Novo' }, { title: 'Ouidah' }],

    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
