var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('h2',[_vm._v("Ajouter un moyen de transport")]),_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.createTransportMeans.apply(null, arguments)}}},[_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Catégorie de camion *","label-for":"mc-first-name"}},[_c('v-select',{attrs:{"id":"pays","label":"name","options":_vm.categoryCamions,"reduce":_vm.categoryCamions.slug,"placeholder":"Catégorie de camion"},model:{value:(_vm.transportMeans.cat_camion_id),callback:function ($$v) {_vm.$set(_vm.transportMeans, "cat_camion_id", $$v)},expression:"transportMeans.cat_camion_id"}})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Type de camion *","label-for":"type_camion"}},[_c('validation-provider',{attrs:{"name":"type camion","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"id":"type_camion","label":"libelle","size":"sm","options":_vm.typeCamions,"reduce":function (typeCamions) { return typeCamions.id; },"state":errors.length > 0 ? false : null,"placeholder":"Type de camion"},model:{value:(_vm.transportMeans.type_camion_id),callback:function ($$v) {_vm.$set(_vm.transportMeans, "type_camion_id", $$v)},expression:"transportMeans.type_camion_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"N° Chassis","label-for":"num_chassis"}},[_c('validation-provider',{attrs:{"name":"numéro chassis","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"num_chassis","size":"sm","state":errors.length > 0 ? false : null,"placeholder":"Numéro chassis"},model:{value:(_vm.transportMeans.num_chassis),callback:function ($$v) {_vm.$set(_vm.transportMeans, "num_chassis", $$v)},expression:"transportMeans.num_chassis"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Immatriculation *","label-for":"immatriculation"}},[_c('validation-provider',{attrs:{"name":"numéro d'immatriculation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"immatriculation","size":"sm","state":errors.length > 0 ? false : null,"placeholder":"Numéro d'immatriculation"},model:{value:(_vm.transportMeans.num_immatriculation),callback:function ($$v) {_vm.$set(_vm.transportMeans, "num_immatriculation", $$v)},expression:"transportMeans.num_immatriculation"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Nombre d'essieux *","label-for":"essieux"}},[_c('validation-provider',{attrs:{"name":"Nombre d'essieux","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"essieux","size":"sm","state":errors.length > 0 ? false : null,"placeholder":"Nombre d'essieux"},model:{value:(_vm.transportMeans.nombre_essieux),callback:function ($$v) {_vm.$set(_vm.transportMeans, "nombre_essieux", _vm._n($$v))},expression:"transportMeans.nombre_essieux"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Libellé *","label-for":"marque"}},[_c('validation-provider',{attrs:{"name":"Libellé","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"marque","size":"sm","state":errors.length > 0 ? false : null,"placeholder":"Libellé"},model:{value:(_vm.transportMeans.libelle),callback:function ($$v) {_vm.$set(_vm.transportMeans, "libelle", $$v)},expression:"transportMeans.libelle"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"4"}},[_c('b-form-group',{attrs:{"label":"Disponibilité *","label-for":"disponible"}}),_c('validation-provider',{attrs:{"name":"disponible","rules":"required"}},[_c('b-form-checkbox',{model:{value:(_vm.transportMeans.disponible),callback:function ($$v) {_vm.$set(_vm.transportMeans, "disponible", $$v)},expression:"transportMeans.disponible"}},[_vm._v(" "+_vm._s(_vm.transportMeans.disponible ? 'Oui' : 'Non')+" ")])],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:" float-right",attrs:{"type":"submit","variant":"primary","disabled":_vm.offreProcess}},[_vm._v(" Enregistrer "),(_vm.offreProcess)?_c('b-spinner',{staticClass:"ml-1",attrs:{"small":"","label":"Loading...","variant":"light"}}):_vm._e()],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(186, 191, 199, 0.15)'),expression:"'rgba(186, 191, 199, 0.15)'",modifiers:{"400":true}}],staticClass:"mr-1 float-right",attrs:{"type":"reset","variant":"secondary"}},[_vm._v(" Rafraichir ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }